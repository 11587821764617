import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import IbizLogo from '../Assests/Images/iBizAccounts.png';
import SideImg from '../Assests/Images/5.png';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { API_BASE_URL } from '../config';

export default function SignUp() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [displayEmail, setDisplayEmail] = useState(true);
    const [displayOtp, setDisplayOtp] = useState(false);
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [vendorCode, setVendorCode] = useState('');
    const [userDataSession, setUserDataSession] = useState([]);
    const navigate = useNavigate();
   

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          setUserDataSession(userData);
          console.log(userData)
        //   navigate('/supplier-registration');
        const enteredOtp = otp.join('');
        const storedOtp = sessionStorage.getItem('otp');

        if (enteredOtp === storedOtp) {
            navigate('/supplier-registration');
        }
        } else {
          console.log("User data not found in sessionStorage");
        }
      }, [navigate,setUserDataSession]);

   

    const [addFormData, setAddFormData] = useState({
        orgid: "",
        userid: 0,
        Supplier: {
            id:0,
            SupplierName: "",
            EmailId: "",
            PhoneNo: "",
            IsVendor: 1,
            IsActive: 1,
            CreatedBy: 0
        },
        bank: []
    });

    

    const handleAddInputChange = (e) => {
        const { name, value } = e.target;
        const [objectName, key] = name.split(".");

        if (objectName && key) {
            setAddFormData((prevState) => ({
                ...prevState,
                [objectName]: {
                    ...prevState[objectName],
                    [key]: value,
                },
            }));
        } else {
            setAddFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const generateOtp = () => {
        return Math.floor(100000 + Math.random() * 900000).toString(); 
    };
    const handleAddSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
    
        const formattedData = {
            orgid: addFormData.orgid,
            userid: addFormData.userid,
            Supplier: {
                ...addFormData.Supplier,
            },
            bank: addFormData.bank,
        };
    
        console.log(formattedData, "sending data to add API.");
    
        Axios.post(`${API_BASE_URL}Inventory/InsertSuppliers`, formattedData)
            .then((response) => {
                console.log(response.data, "Full response data");
                console.log(response.status);
    
                if (response.status === 200) {
                    if (response.data.error) {
                        if (response.data.error.includes("Email already exists")) {
                            setErrorMessage(response.data.error);
                            Swal.fire({
                                title: "Error",
                                text: response.data.error,
                                icon: "error",
                            });
                            return;
                        } else {
                            setErrorMessage(response.data.error);
                            Swal.fire({
                                title: "Error",
                                text: response.data.error,
                                icon: "error",
                            });
                            return;
                        }
                    }
    
                    // **New Section: GET Request to fetch data using EmailId**
                    const emailId = addFormData.Supplier.EmailId;
                    Axios.get(`${API_BASE_URL}Inventory/GetSupplierByEmailId?EmailId=${emailId}`)
                        .then((getResponse) => {
                            // Handle the fetched data as needed
                            console.log(getResponse.data, "Fetched supplier data");
                            
                            const supplierData = getResponse.data.ResultData[0];
                            sessionStorage.setItem('userData', JSON.stringify(supplierData));
                            console.log(supplierData)
                            const orgid = supplierData?.OrgId;
                           
                            if (orgid) {
                                // Store the fetched data in sessionStorage
                             
                               
    
                                const otp = generateOtp();
                                sessionStorage.setItem('otp', otp);
    
                                setEmail(addFormData.Supplier.EmailId);
                                console.log(addFormData.Supplier.EmailId);
    
                                setDisplayOtp(true);
                                setDisplayEmail(false);
    
                                // Send OTP to email only if OrgId is present
                                sendOtpToEmail(addFormData.Supplier.EmailId, otp,orgid)
                                    .then((success) => {
                                        if (success) {
                                            Swal.fire({
                                                title: `Supplier registered successfully. OTP sent to ${addFormData.Supplier.EmailId}.`,
                                                icon: "success",
                                            });
                                        } else {
                                            setErrorMessage('Failed to send OTP. Please try again.');
                                        }
                                    });
                            } else {
                                setErrorMessage('OrgId is missing. Cannot send OTP.');
                                Swal.fire({
                                    title: "Error",
                                    text: 'OrgId is missing. Cannot send OTP.',
                                    icon: "error",
                                });
                            }
                        })
                        .catch((getError) => {
                            console.error("Error fetching supplier data:", getError);
                            const getErrorMessage = getError.response?.data?.error || "Failed to fetch supplier data";
                            setErrorMessage(getErrorMessage);
                            Swal.fire({
                                title: "Error",
                                text: getErrorMessage,
                                icon: "error",
                            });
                        });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "Failed to register supplier.",
                        icon: "error",
                    });
                }
            })
            .catch((error) => {
                console.error("Error adding supplier:", error);
    
                const errorMessage = error.response?.data?.error || "An error occurred";
                setErrorMessage(errorMessage);
    
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                });
    
                sessionStorage.setItem('errorMessage', errorMessage);
            })
            .finally(() => {
                setLoading(false);  // Stop the loading indicator
            });
    };
    
    
    const handleOtpChange = (index, value) => {
        if (/^[0-9]*$/.test(value) && value.length <= 1) { 
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

          
            if (value && index < otp.length - 1) {
                otpInputRefs[index + 1].current.focus();
            } else if (!value && index > 0) {
                otpInputRefs[index - 1].current.focus(); 
            }
        }
    };

    const sendOtpToEmail = async (email, otp,orgid) => {
        const emailData = {
            superid: orgid,
            toaddr: email,
            message: `Your OTP is: ${otp}`,
            subject: "Your OTP Code"
        };

        try {
            const emailResponse = await fetch('https://notifyservisesrc.azurewebsites.net/notify/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });

            const responseData = await emailResponse.json();

            console.log('Email Service Response:', responseData);

            return responseData.success; 
        } catch (error) {
            console.error('Error sending OTP:', error);
            return false; 
        }
    };

    const handleSubmitOtp = () => {
        const enteredOtp = otp.join('');
        const storedOtp = sessionStorage.getItem('otp');

        if (enteredOtp === storedOtp) {
            navigate('/supplier-registration');
        } else {
            setOtpError('Invalid OTP. Please try again.');
        }
    };

       return (
            <div className="main-wrapper d-flex">
                <div className="account-content flex-grow-1 d-flex col-md-6 col-lg-6 col-12">
                    <div className="login-wrapper flex-grow-1 d-flex">
                        <div className="login-content flex-grow-1">
                            {displayEmail && (
                                  <form onSubmit={handleAddSubmit}>
                                  <div className="login-userset">
                                      <div className="login-logo logo-normal">
                                          <img src={IbizLogo} alt="Ibizlogo" />
                                      </div>
                                      <div className="login-userheading">
                                          <h3>Sign Up</h3>
                                          <h4>Perennial POS application has features that help you build <br /> solid relationships with customers, track sales activities, <br />automate and keep sales processes streamlined.</h4>
                                      </div>
  
                                      <div className="form-login mb-3">
                                          <label className="form-label">Name</label>
                                          <div className="form-addons position-relative">
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  name="Supplier.SupplierName"
                                                  value={addFormData.Supplier.SupplierName}
                                                  onChange={handleAddInputChange}
                                                  placeholder="Enter supplier name"
                                                  required
                                              />
                                          </div>
                                      </div>
  
                                      <div className="form-login mb-3">
                                          <label className="form-label">Email Id</label>
                                          <div className="form-addons position-relative">
                                              <input
                                                  type="email"
                                                  className="form-control"
                                                  name="Supplier.EmailId"
                                                  value={addFormData.Supplier.EmailId}
                                                  onChange={handleAddInputChange}
                                                  placeholder="Enter email"
                                                  required
                                              />
                                              <i className="fa-regular fa-envelope position-absolute end-0 me-3" style={{ top: '50%', transform: 'translateY(-50%)' }}></i>
                                          </div>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                          <div className="form-login mb-3 w-50">
                                              <label className="form-label">Mobile Number</label>
                                              <div className="form-addons position-relative">
                                                  <input
                                                      type="tel"
                                                      className="form-control"
                                                      name="Supplier.PhoneNo"
                                                      value={addFormData.Supplier.PhoneNo}
                                                      onChange={handleAddInputChange}
                                                      placeholder="Enter phone number"
                                                      required
                                                  />
                                              </div>
                                          </div>
                                          <div className="form-login mb-3">
                                              <label className="form-label">Vendor Code</label>
                                              <div className="form-addons position-relative">
                                                  <input
                                                      type="text"
                                                      className="form-control"
                                                      name="orgid"
                                                      value={addFormData.orgid}
                                                      onChange={handleAddInputChange}
                                                      placeholder="Enter Vendor ID"
                                                      required
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                      <p className="mb-3 text-danger">{errorMessage}</p>
                                      <div className="form-login mb-3">
                                          <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                                              {loading ? "Signing Up..." : "Sign Up"}
                                          </button>
                                      </div>
                                      <p className="mb-0 text-center">
                                          Already have an account? <Link to="/">Login Here</Link>
                                      </p>
                                  </div>
                              </form>
                            )}
        
                            {displayOtp && (
                                <div className="p-2 text-center">
                                    <div className="login-logo logo-normal">
                                        <img src={IbizLogo} alt="Ibizlogo" />
                                    </div>
                                    <h4>Enter OTP</h4>
                                    <div>
                                        <span>A code has been sent to <b className='text-success'>{email}</b></span>
                                    </div>
                                    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-5">
                                        {otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                className="m-2 text-center form-control shadow"
                                                type="text"
                                                maxLength="1"
                                                style={{ width: '2.7rem' }}
                                                value={digit}
                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                ref={otpInputRefs[index]}
                                                required
                                            />
                                        ))}
                                    </div>
                                    {otpError && <p style={{ color: 'red' }}>{otpError}</p>}
                                    <button type='button' className='btn btn-primary mt-3 text-capitalize' onClick={handleSubmitOtp}>Submit</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="login-image col-md-6 d-none d-lg-block">
                        <img src={SideImg} alt="SideImage" />
                    </div>
                </div>
            </div>
        );
        
    
}
