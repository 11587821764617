import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IbizLogo from "../Assests/Images/iBizAccounts.png";
import PendingPayment from './pendingpayment.svg';
import UserIcon from './usericon.svg';
import { API_BASE_URL } from "../config";
import Axios from "axios";


export default function Dashboard() {

    const [userDataSession, setUserDataSession] = useState([]);
    const [dashboardTablesData, setDashboardTablesData] = useState([]);
    const [dashboardCountsData, setDashboardCountsData] = useState([]);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
        const userData = JSON.parse(userDataString);
        setUserDataSession(userData);
        console.log(userData);
        } else {
        console.log("User data not found in sessionStorage");
        }
    }, []);

    useEffect(() => {
        const fetchDashboardData = async () => {
          const table_api = `${API_BASE_URL}Dashboard/SupplierDashboard?OrgId=${userDataSession.OrgId}&SupplierId=${userDataSession.Id}`;
          try {
            const tablesResponse = await Axios.get(table_api);
            setDashboardCountsData(tablesResponse.data.DashboardCounts);
            setDashboardTablesData(tablesResponse.data.RecentPOs);
            console.log(tablesResponse.data, "tableresposne.dataaaaa");
    
          } catch (error) {
            console.error(
              "Error fetching dashboard data:",
              error.response ? error.response.data : error.message
            );
          }
        };
    
        if (userDataSession && userDataSession.OrgId) {
          fetchDashboardData();
        }
      }, [userDataSession]);

    const handleLogout = () => {

        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("otp")

        setUserDataSession(null);
        window.location.href = "/";
        console.log("User logged out");
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="main-wrapper">
            <div className="header">
                <div className="header-left active">
                    <a href="/dashboard" className="logo logo-normal">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a href="/dashboard" className="logo logo-white">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a href="/dashboard" className="logo-small">
                        <img src={IbizLogo} alt="" />
                    </a>
                    <a id="toggle_btn">
                        <i data-feather="chevrons-left" className="feather-16"></i>
                    </a>
                </div>

                <ul className="nav user-menu">
                    <li className="nav-item nav-searchinputs">
                        <div className="top-nav-search">
                            <a className="responsive-search">
                                <i className="fa fa-search"></i>
                            </a>
                            <form action="#" className="dropdown"></form>
                        </div>
                    </li>

                    <li className="nav-item dropdown has-arrow main-drop">
                        <a className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                            <span className="user-info">
                                <span className="user-letter text-primary fs-3">{userDataSession?.SupplierName?.charAt(0)}</span>
                                <span className="user-detail">
                                    <span className="user-name">
                                        {userDataSession.SupplierName}
                                    </span>
                                    <h5 className="profilesets">Vendor</h5>
                                    <span className="user-role"></span>
                                </span>
                            </span>
                        </a>
                        <div className="dropdown-menu menu-drop-user">
                            <div className="profilename">
                                <div className="profileset">
                                    <span className="user-img">
                                        <img src={UserIcon} alt="usericon" />
                                        {/* <span className="status online"></span> */}
                                    </span>
                                    <div className="profilesets">
                                        <h6>{userDataSession.SupplierName}</h6>
                                        <h5>Vendor</h5>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <Link to="/supplier-registration">
                                    <a
                                        className="dropdown-item logout pb-0 text-info"
                                    >
                                       <i className="fa-regular fa-user me-2"></i>
                                        Profile
                                    </a>
                                </Link>
                                <hr className="m-0" />
                                <Link to="/">
                                    <a className="dropdown-item logout pb-0" onClick={handleLogout}>
                                        <i className="fa-solid fa-right-from-bracket me-2"></i>
                                        Logout
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </li>
                </ul>

                <div className="dropdown mobile-user-menu">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item text-info" href="supplier-registration">
                            <i className="fa-regular fa-user me-2"></i> Profile
                        </a>
                        <a className="dropdown-item text-danger" onClick={handleLogout}>
                            <i className="fa-solid fa-right-from-bracket me-2"></i> Logout
                        </a>
                    </div>
                </div>
            </div>
            <div className="page-wrapper ">
                <div className="content">
                    <div className="welcome d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center welcome-text">
                            <h3 className="d-flex align-items-center">
                                <img src="assets/img/icons/hi.svg" alt="img" />
                                &nbsp;Hi {userDataSession.SupplierName}
                            </h3>
                            &nbsp;
                            <h6>here&apos;s an overview of your latest updates..</h6>
                        </div>
                    </div>

                    {/* cards */}
                    <div className="row sales-cards justify-content-evenly">
                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-primary mb-4">
                                    <img src={PendingPayment} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">₹ {dashboardCountsData?.PendingAmt || 0}</h4>
                                    <p>Pending Payment</p>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-success mb-4">
                                    <img src={PendingPayment} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">₹ {dashboardCountsData?.PaidAmount || 0}</h4>
                                    <p>Paid Payment</p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-info mb-4">
                                    <img src={PendingPayment} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">{dashboardCountsData?.POcount || 0}</h4>
                                    <p>No.of PO's Served</p>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-xl-3 col-sm-6 col-6">
                            <Link to="/dashboard">
                                <div className="card color-info bg-secondary mb-4">
                                    <img src={PendingPayment} alt="img" style={{ width: "2.7rem" }} />
                                    <h4 className="text-white">{dashboardCountsData?.CompletedPOs || 0}</h4>
                                    <p>Completed PO's</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12 d-flex">
                            <div className="card flex-fill default-cover w-100 mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h4 className="card-title mb-0 text-info">
                                        <i className="fa-solid fa-cubes me-2"></i>
                                        Recent Requests
                                    </h4>
                                    <div className="dropdown">
                                        <Link to="/dashboard" className="view-all d-flex align-items-center">
                                            <span className="ps-2 d-flex align-items-center">
                                                <i className="fa-solid fa-ellipsis text-info"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive" style={{ height: '20rem', overflowY: 'auto' }}>
                                        <table className="table table-borderless best-seller">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>PO Number</th>
                                                    <th>PO Date</th>
                                                    <th>Paid Amount</th>
                                                    <th>Due Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dashboardTablesData && dashboardTablesData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>PO-{item.AutoGenTransactionNo}</td>
                                                        <td>{formatDate(item.PODate)}</td>
                                                        <td>{item.PaidAmount ? item.PaidAmount : 0}</td>
                                                        <td>{(item.InvoiceAmount) - (item.PaidAmount)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
